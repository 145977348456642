import Head from "next/head";
import dayjs from "dayjs";
import * as Sentry from "@sentry/nextjs";
import { useWallet } from "@solana/wallet-adapter-react";
import { INFT, NFT, TierConfig } from "@helpers/nfts";
import { FarmAccount, GemFarm } from "@helpers/anchor";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { Collection } from "@helpers/use-request";
import numeral from "numeral";
import { Button } from "@components/button";
import { Modal } from "@components/modals/component/modal";
import { TopBar } from "@components/modals/component/topbar";
import Image from "next/image";
import { PickWallet } from "@components/modals/pick-wallet";
import classNames from "classnames";
import { Spacer } from "@utils/spacer";
import defaultCollection from '@helpers/collection'

import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { parseDate, parseDateToUnix } from "@helpers/account-utils";
import { useToasts } from "@helpers/toast";
import Countdown from "react-countdown";
import { IncomingMessage } from "http";
import { useInterval } from "@helpers/use-interval";
import { useValueLocked } from "@helpers/use-value-locked";

export interface CollectionWithNfts extends Collection {
  tokens: INFT[];
  farmAccount?: FarmAccount;
}

export function InfoItem({
  label,
  value,
  size = "large",
}: PropsWithChildren<{
  label: string;
  value?: string | number;
  size?: "small" | "large";
}>) {
  return (
    <div className="flex flex-col items-center">
      <p className="capitalize">{label}</p>

      <h2
        className={classNames("text-[#6333ff]", {
          "text-6xl": size === "large",
          "text-3xl": size === "small",
        })}
      >
        {value}
      </h2>
    </div>
  );
}

export function parseUnixToDaysLeft(unixTsSec: BN) {
  const unixBN = new BN(unixTsSec);

  if (unixBN.eq(new BN(0))) {
    return "--";
  }

  const date = new Date(unixBN.mul(new BN(1000)).toNumber());

  return Math.floor(
    (date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  );
}

function getNftLockDetails(nft: INFT) {
  const vault = nft.vault!.account;
  const isTier0 = vault.rewardA?.rewardTier?.requiredTenure?.toNumber() === 0

  const lockExpiry = isTier0 ? null : parseDate(
    vault.rewardA?.stakedAt?.add(vault.rewardA.rewardTier.requiredTenure)
  );

  const lockPeriodExpired = isTier0 ? null : lockExpiry!.getTime() < new Date().getTime();

  return {
    staked: nft.staked,
    lockPeriodExpired,
    lockExpiry,
    isLocked: isTier0 ? false : !lockPeriodExpired,
    rewardA: {
      lastRewardsClaimedAt: parseDate(vault.rewardA.lastRewardsClaimedAt),
      stakedAt: parseDate(vault.rewardA.stakedAt),
      rewardTier: {
        requiredTenure: vault.rewardA.rewardTier.requiredTenure.toNumber(), // required tenure in seconds
        requiredTenureInDays:
          vault.rewardA.rewardTier.requiredTenure.toNumber() / 86400,
        rewardRate: vault.rewardA.rewardTier.rewardRate.toNumber(),
      },
    },
  } as any;
}

export function computeRewardRate(rewardRate?: number) {
  return rewardRate ? numeral(rewardRate * 86400)?.format("0,0[.]00") : null;
}

export function EstimatedRewards({
  collection,
}: PropsWithChildren<{
  collection: CollectionWithNfts;
}>) {
  const [, setRefreshDashboard] = useState(0);
  const stakedTokens = collection.tokens.filter((token) => token.staked);

  useInterval(() => {
    setRefreshDashboard((refresh) => refresh + 1);
  }, 5000);

  const estimatedRewards = stakedTokens.reduce((total, token) => {
    const lastRewardsClaimedAt = parseDate(
      token.vault!.account.rewardA.lastRewardsClaimedAt
    );
    const now = new Date();

    const differenceInSeconds = dayjs(now).diff(
      lastRewardsClaimedAt,
      "seconds"
    );

    // let's subs

    const rewards =
      ((token.vault!.account.rewardA.rewardTier.rewardRate.toNumber() / 86400) *
        differenceInSeconds) /
      10 ** parseInt(collection?.decimals);

    return rewards + total;
  }, 0);

  return (
    <InfoItem
      label={`Estimated rewards`}
      value={`${numeral(estimatedRewards).format("0,00.000")} ${collection?.rewardSymbol
        }`}
      size="small"
    />
  );
}

export default function SingleFarm() {
  const wallet = useWallet();
  const nftClient = new NFT(wallet);
  const farm = new GemFarm(wallet);
  const { toast } = useToasts();
  const [sentryUser, setSentryUser] = useState<any>({})
  const { loading: loadingTotalValue, value: totalValueLocked, failed: failedLoadingTotalValueLocked } = useValueLocked()

  const [isStaking, setIsStaking] = useState<INFT>();
  const [isUnstaking, setIsUnstaking] = useState<INFT>();
  const [selectedNft, setSelectedNft] = useState<INFT>();
  const [selectedTier, setSelectedTier] = useState<string>();
  const [confirmingBreakBank, setConfirmingBreakBank] = useState(false);
  const [pickingStakingTier, setPickingStakingTier] = useState(false);
  const [nftFilter, setNftFilter] = useState("All");
  const [pickWalletOpen, setPickWalletOpen] = useState(false);
  const [collection, setCollection] = useState<CollectionWithNfts>(
    defaultCollection as any
  );
  const [isClaiming, setIsClaiming] = useState(false);
  const [stakingAll, setStakingAll] = useState(false);
  const [loadingFarmAccount, setLoadingFarmAccount] = useState(true);
  const [loadingFarmInformation, setLoadingFarmInformation] = useState(true);

  async function fetchNftsInWallet(): Promise<INFT[]> {
    const nfts = await nftClient.getNftsInWallet();

    return nfts.map((nft) => ({
      ...nft.externalMetadata,
      ...nft,
      address: nft.pubkey?.toBase58?.(),
      gemMint: nft?.mint,
      pubkey: nft?.pubkey,
      onchainMetadata: nft?.onchainMetadata,
    }));
  }

  function computeAllFarmStats() {
    if (!collection.farmAccount) {
      return {
        totalStaked: 0,
        percentageStaked: 0,
        minimumValueStaked: 0,
      };
    }

    const totalStaked = collection.farmAccount?.vaultCount.toNumber();

    const totalItems = parseInt(collection.totalItems as any as string);

    const percentageStaked = totalStaked / totalItems;

    return {
      totalStaked: numeral(totalStaked).format("0,00"),
      percentageStaked: numeral(percentageStaked).format("0,00.00%"),
      minimumValueStaked: loadingTotalValue ? '0' : failedLoadingTotalValueLocked ? '-' : numeral(totalValueLocked * totalStaked).format("$ 0,00.00"),
    };
  }

  const farmStats = computeAllFarmStats();

  async function fetchFarmAccount() {
    const farmAccount = await farm.fetchFarmAcc(
      new PublicKey(collection.farmAddress!)
    );

    setCollection({
      ...collection,
      farmAccount: {
        ...farmAccount,
        hasPaperHandsTax: !!farmAccount?.config?.paperHandsTaxLamp?.toNumber(),
        paperHandsTaxInSol: numeral(
          farmAccount?.config?.paperHandsTaxLamp?.toNumber() / LAMPORTS_PER_SOL
        ).format("0,00.00"),
      } as FarmAccount,
    });
    setLoadingFarmAccount(false);
  }

  function resolveTierOptions() {
    if (loadingFarmInformation) {
      return [];
    }
    const options: TierConfig[] = [];

    const schedule = collection?.farmAccount?.rewardA?.fixedRate?.schedule!;

    if (!schedule) {
      return [];
    }
    options.push(schedule?.tier0);

    if (schedule?.tier1) {
      options.push(schedule.tier1);
    }

    if (schedule?.tier2) {
      options.push(schedule.tier2);
    }

    if (schedule?.tier3) {
      options.push(schedule.tier3);
    }

    return options;
  }

  const tierOptions = resolveTierOptions().map((option, idx) => ({
    ...option,
    value: `tier${idx}`,
    name: `Schedule ${idx + 1}`,
    formattedRewardRate: numeral(
      option.rewardRate.toNumber() / 10 ** parseInt(collection?.decimals)
    ).format("0,00"),
    formattedRequiredTenure: numeral(
      option.requiredTenure.div(new BN(86400)).toNumber()
    ).format("0,00"),
  }));

  async function fetchAllFarmingData(afterStaking = false) {
    setLoadingFarmInformation(true)
    setLoadingFarmAccount(true)
    let [allNftsInWallet, stakedNfts] = await Promise.all([
      fetchNftsInWallet(),
      farm.fetchAllVaultAccountsForFarmAndOwner(
        new PublicKey(collection.farmAddress!)
      ),
    ]);

    const nftsFromCollection = allNftsInWallet.filter(
      (nft) =>
        collection?.candyMachine ===
        nft?.onchainMetadata?.data?.creators?.[0]?.address
    );

    const newCollection: CollectionWithNfts = {
      ...collection,
      rewardSymbol: collection.rewardSymbol?.startsWith("$")
        ? collection.rewardSymbol
        : `$${collection.rewardSymbol}`,
      tokens: [
        ...(afterStaking ? [] : nftsFromCollection.map((nft, tokenIdx: number) => ({
          ...nft,
          staked: false,
          locked: false,
        }))),
        ...stakedNfts.map((nft) => {
          return {
            ...nft,
            staked: true,
            locked: true,
            lockDetails: getNftLockDetails(nft),
          };
        }),
      ],
    }

    setCollection(newCollection);

    Sentry.setUser({ id: wallet.publicKey?.toBase58(), mints: newCollection.tokens.map(token => ({ mint: token.gemMint.toBase58(), staked: token.staked })) });

    setSentryUser({ id: wallet.publicKey?.toBase58(), mints: newCollection.tokens.map(token => ({ mint: token.gemMint.toBase58(), staked: token.staked })) })

    setLoadingFarmInformation(false);
    setLoadingFarmAccount(false);

    // @ts-ignore
    window.allVaults = await farm.farmProgram.account.vault.all()
    // @ts-ignore

  }

  // when an nft is staked, we'll first of all load the nft from the blockchain vault
  // secondly we'll remove the unstaked one from tokens (setTokens)
  // thirdly we'll insert the newly staked token into farmAccounts
  async function onStaked(
    nft: INFT,
    tier?: typeof tierOptions[number],
    unstaked = false
  ) {
    const gemsStaked = unstaked
      ? collection?.farmAccount?.vaultCount?.sub(new BN(1))
      : collection?.farmAccount?.vaultCount?.add(new BN(1));

    const now = new Date();

    // after stake, let's create a fake vault and add on the token
    const vault = {
      account: {
        farm: new PublicKey(collection?.farmAddress!),
        owner: wallet?.publicKey,
        locked: true,
        gemMint: nft?.gemMint,
        rewardA: {
          paidOutReward: new BN(0),
          stakedAt: parseDateToUnix(now),
          reservedAmount: new BN(0),
          rewardTier: {
            rewardRate: tier?.rewardRate || collection?.farmAccount?.rewardA?.fixedRate?.schedule?.tier0?.rewardRate,
            requiredTenure: tier?.requiredTenure || collection?.farmAccount?.rewardA?.fixedRate?.schedule?.tier0?.requiredTenure,
          },
          lastRewardsClaimedAt: parseDateToUnix(now),
        },
      },
    };

    const updatedNft = {
      ...nft,
      vault: vault as any,
    }

    setCollection({
      ...collection,
      farmAccount: {
        ...collection?.farmAccount,
        gemsStaked,
      } as FarmAccount,
      tokens: collection.tokens.map((token) => {
        if (token.gemMint.toBase58() === nft?.gemMint.toBase58()) {
          return {
            ...token,
            ...updatedNft,
            staked: !token.staked,
            lockDetails: getNftLockDetails(updatedNft)
          };
        }

        return token;
      }),
    });
  }

  // when an nft is unstaked, we'll first of all load the nft from the user's wallet
  // secondly, we'll remove the staked one from the collections.tokens
  // thirdly we'll insert the unstaked on into setTokens
  function onUnstaked(nft: INFT, tier: any = collection?.farmAccount?.rewardA?.fixedRate?.schedule?.tier0) {
    onStaked(nft, tier, true);
  }

  useEffect(() => {
    if (wallet.connected && loadingFarmInformation) {
      fetchAllFarmingData();
    }

    // eslint-disable-next-line
  }, [wallet.connected]);

  useEffect(() => {
    fetchFarmAccount();

    // eslint-disable-next-line
  }, []);

  const totalStaked = useMemo(
    () => collection?.tokens?.filter((token) => token.staked)?.length || 0,
    [collection]
  );

  const tokens = useMemo(
    () =>
      collection?.tokens?.filter((token) =>
        nftFilter === "Staked"
          ? token?.staked
          : nftFilter === "Unstaked"
            ? !token?.staked
            : true
      ) || [],
    [collection, nftFilter]
  );

  let notFoundTokensMessage = (
    <p>No {collection?.nftName} found in your wallet.</p>
  );

  if (nftFilter === "Staked") {
    notFoundTokensMessage = (
      <p>You have not staked any {collection?.nftName}.</p>
    );
  }

  if (nftFilter === "All" || collection.tokens.length === 0) {
    notFoundTokensMessage = (
      <p className="text-center">
        You have no {collection?.nftName}. Purchase one from <br />
        <a href={collection?.magicEdenUrl} className="text-[#6333ff]">
          Our Magic Eden Marketplace
        </a>
      </p>
    );
  }

  function onClaim() {
    setIsClaiming(true);
    const allStaked = collection.tokens.filter((token) => token.staked);

    farm
      .claimRewards(
        new PublicKey(collection?.farmAddress!),
        allStaked.map((nft) => nft.gemMint).filter(mint => !!mint)
      )
      .then(() => {
        toast({
          message: "Successfully! You've claimed your staking rewards.",
        });

        fetchAllFarmingData()
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error, { user: sentryUser })
        toast({
          message:
            "Failed! We couldn't claim your rewards. Please refresh the page and try again.",
          variant: "error",
        });
      })
      .finally(() => setIsClaiming(false));
  }

  function resolveFarmerStats() {
    if (!collection.tokens) {
      return {
        totalRewardRate: 0,
      };
    }

    const stakedTokens = collection.tokens.filter((token) => token.staked);
    const totalRewardRate = stakedTokens.reduce(
      (total, token) =>
        token.vault!.account.rewardA.rewardTier.rewardRate.toNumber() + total,
      0
    );

    return {
      totalRewardRate: totalRewardRate / 10 ** parseInt(collection?.decimals),
    };
  }

  console.log("@collections.tokens", (collection.tokens || []).map(token => ({
    mint: (token.gemMint || token.mint).toBase58(),
    staked: token.staked
  })));

  const farmerStats = resolveFarmerStats();

  function unstakeNft(nft: INFT) {
    setIsUnstaking(nft);
    farm
      .withdrawNft(
        new PublicKey(collection.farmAddress!),
        nft.gemMint,
        collection?.farmAccount?.rewardA?.rewardMint!
      )
      .then(() => {
        onUnstaked(nft);
        toast({
          message: `Success ! Your ${collection?.nftName} has been unstaked.`,
        });
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error, { user: sentryUser })
        toast({
          message: `Failed! We couldn't unstake your ${collection?.nftName}. Please refresh the page and try again.`,
          variant: "error",
        });
      })
      .finally(() => {
        setIsUnstaking(undefined);
        setSelectedNft(undefined);
        setConfirmingBreakBank(false);
      });
  }

  function stakeNftWithTier() {
    const tier = tierOptions.find(
      (tierOption) => tierOption.value === selectedTier
    );
    if (!selectedNft || !selectedTier || !tier) {
      return;
    }

    const isTier0 =
      tierOptions.findIndex((option) => option.value === selectedTier) === 0;

    setIsStaking(selectedNft);

    farm
      .depositNft(
        new PublicKey(collection.farmAddress!),
        selectedNft.gemMint,
      )
      .then(({ vaultAccount }) => {
        const updatedNft = {
          ...selectedNft,
          vault: vaultAccount as INFT["vault"],
        };

        toast({
          message: `Success ! Your ${collection?.nftName} has been staked.`,
        });
        setPickingStakingTier(false);
        setSelectedNft(undefined);
        setSelectedTier(undefined);
        onUnstaked(
          {
            ...updatedNft,
            lockDetails: getNftLockDetails(updatedNft),
          },
          tier
        );
        setIsStaking(undefined);
      })
      .catch((error) => {
        Sentry.captureException(error, {
          user: sentryUser, contexts: {
            deposit: {
              selectedNft: selectedNft?.gemMint?.toBase58()
            }
          }
        })
        setIsStaking(undefined);
        setSelectedNft(undefined);
        setPickingStakingTier(false);
        console.error(error);
        toast({
          message: `Failed. We couldn't stake your ${collection?.nftName}. Please refresh the page and try again.`,
          variant: "error",
        });
      });
  }

  function bulkStakeNft() {
    setStakingAll(true)

    const allTokens = collection.tokens.filter(token => !token.staked)

    if (allTokens.length === 0) {
      return
    }

    farm.bulkDepositNfts(new PublicKey(collection.farmAddress!), allTokens.map(token => new PublicKey(token.gemMint || token.mint)))
      .then(({ txSigs }) => {
        setStakingAll(false)

        const allSucceeded = txSigs.filter(tx => tx.status === 'fulfilled')

        if (allSucceeded.length > 0) {
          fetchAllFarmingData(true)
        }

        if (allSucceeded.length === txSigs.length) {
          toast({
            message: `Successfully staked all your NFTs`,
          });

          return
        }

        if (allSucceeded.length > 0) {
          toast({
            message: `Successfully staked only ${allSucceeded.length} / ${txSigs.length} of your NFTs. Please refresh and try to stake the rest again.`,
          });
        }

        if (allSucceeded.length === 0) {
          toast({
            message: `Failed. We couldn't stake all your nfts. Please refresh the page and try again.`,
            variant: 'error'
          });

          txSigs.filter(tx => tx.status === 'rejected').forEach(tx => Sentry.captureException((tx as PromiseRejectedResult).reason))
        }
      })
      .catch((error) => {
        Sentry.captureException(error, { user: sentryUser })
        console.error(error)
        setStakingAll(false)
        setIsStaking(undefined);
        toast({
          message: `Failed. We couldn't stake all your nfts. Please refresh the page and try again.`,
          variant: 'error'
        });
      });
  }

  function stakeNft(nft: INFT) {
    setIsStaking(nft);
    farm
      .depositNft(
        new PublicKey(collection.farmAddress!),
        nft.gemMint,
      )
      .then(() => {
        onStaked(nft);
        setIsStaking(undefined);
        toast({
          message: `Success ! Your ${collection?.nftName} has been staked.`,
        });
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error, { user: sentryUser })
        setIsStaking(undefined);
        toast({
          message: `Failed. We couldn't stake your ${collection?.nftName}. Please refresh the page and try again.`,
          variant: 'error'
        });
      });
  }

  const canClaim = loadingFarmInformation
    ? false
    : collection.tokens.filter((token) => token.staked).length > 0;

  const canStakeAll = true

  return (
    <>
      <Head>
        <title>{collection.name} - Staking</title>
        <meta
          name="description"
          content="Stake your NFTs and get daily rewards."
        />
      </Head>

      <Modal
        open={confirmingBreakBank}
        setOpen={setConfirmingBreakBank}
        modalStyle="max-w-[540px] w-full bg-neutral-140"
      >
        <div className="px-6 py-4">
          <TopBar
            title={
              collection?.farmAccount?.paperHandsTaxInSol
                ? "Confirm unstake"
                : "Cannot unstake"
            }
            setOpen={setConfirmingBreakBank}
          />
        </div>

        <div className="border-t border-neutral-160 border-opacity-70 w-full"></div>

        <Spacer className="h-4" />

        <div className="px-6 pb-6 text-neutral-180">
          {collection?.farmAccount?.hasPaperHandsTax ? (
            <>
              <p>
                Your {collection?.nftName} is locked until{" "}
                <span className="font-bold">
                  {dayjs(selectedNft?.lockDetails?.lockExpiry).format(
                    "MMM D, YYYY h:mm A	"
                  )}
                </span>
                . To break your vault and unstake before the lock date.
              </p>

              <p className="mt-2">
                You&apos;ll pay a tax of
                <span className="font-bold">
                  {collection?.farmAccount?.paperHandsTaxInSol} SOL
                </span>
                if you want to unstake now. Would you like to proceed ?
              </p>
            </>
          ) : (
            <p>
              Your {collection?.nftName} is locked until{" "}
              <span className="font-bold">
                {dayjs(selectedNft?.lockDetails?.lockExpiry).format(
                  "MMM D, YYYY h:mm A	"
                )}
              </span>
            </p>
          )}

          {collection?.farmAccount?.hasPaperHandsTax ? (
            <div className="w-full mt-4 flex justify-end space-x-4">
              <button
                className={classNames(
                  "rounded-[9px] h-14 px-6 border-2 border-neutral-160 hover:border-[#6333ff] transition ease-linear"
                )}
                onClick={() => {
                  setConfirmingBreakBank(false);
                  setSelectedNft(undefined);
                }}
              >
                Cancel
              </button>
              <Button
                size="large"
                variant="danger-solid"
                onClick={() => unstakeNft(selectedNft!)}
                isLoading={isUnstaking?.name === selectedNft?.name}
              >
                Unstake
              </Button>
            </div>
          ) : (
            <div className="flex items-center justify-end mt-4">
              <button
                className={classNames(
                  "rounded-[9px] h-14 px-6 border-2 border-neutral-160 hover:border-[#6333ff] transition ease-linear"
                )}
                onClick={() => {
                  setConfirmingBreakBank(false);
                  setSelectedNft(undefined);
                }}
              >
                Ok
              </button>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={pickingStakingTier}
        setOpen={(isOpen) => {
          if (isStaking) {
            return;
          }

          setPickingStakingTier(isOpen);
        }}
        modalStyle="max-w-[540px] w-full bg-neutral-140"
      >
        <div className="px-6 py-4">
          <TopBar
            title="Select your staking schedule"
            setOpen={setPickingStakingTier}
          />
        </div>

        <div className="border-t border-neutral-160 border-opacity-70 w-full"></div>

        <Spacer className="h-4" />
        <div className="px-6 pb-6 pt-4">
          <RadioGroup value={selectedTier} onChange={setSelectedTier}>
            <RadioGroup.Label className="sr-only font-body">
              Staking schedule
            </RadioGroup.Label>
            <div className="space-y-2">
              {tierOptions.map((plan) => (
                <RadioGroup.Option
                  key={plan.name}
                  value={plan.value}
                  className={({ active, checked }) =>
                    `
                  ${checked
                      ? "border-2 border-secondary-400"
                      : "border border-neutral-160"
                    }
                    relative rounded-lg px-5 py-4 cursor-pointer flex focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium font-body`}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline font-body ${checked ? "text-sky-100" : "text-gray-500"
                                }`}
                            >
                              {plan.formattedRequiredTenure === "0" ? (
                                <span>No lock</span>
                              ) : (
                                <span>
                                  Lock for {plan.formattedRequiredTenure} days
                                </span>
                              )}
                              <span aria-hidden="true" className="ml-1">
                                &middot;
                              </span>{" "}
                              <span>
                                Earn {plan.formattedRewardRate}{" "}
                                {collection?.rewardSymbol} / day
                              </span>
                              {plan.formattedRequiredTenure !== "0" &&
                                collection?.farmAccount?.hasPaperHandsTax ? (
                                <>
                                  <span aria-hidden="true" className="ml-1">
                                    &middot;
                                  </span>{" "}
                                  <span>
                                    Paper hands tax:{" "}
                                    {
                                      collection?.farmAccount
                                        ?.paperHandsTaxInSol
                                    }{" "}
                                    SOL
                                  </span>
                                </>
                              ) : null}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="flex-shrink-0 text-secondary-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <div className="w-full mt-4 flex justify-end">
            <Button
              size="large"
              onClick={stakeNftWithTier}
              isDisabled={!selectedTier}
              isLoading={isStaking?.name === selectedNft?.name}
            >
              Stake
            </Button>
          </div>
        </div>
      </Modal>

      <div
        className="flex flex-col justify-between w-full min-h-screen"
        style={{
          backgroundColor: "#F8F3EE",
          color: "#403d38",
          fontFamily:
            'Manrope, "ui-serif", Georgia, Cambria, "Times New Roman", Times, serif',
        }}
      >
        <header className="h-16 md:border-b border-neutral-160 flex flex-col md:flex-row md:items-center justify-between md:px-12 z-10">
          <p className="flex items-center space-x-4 border-b md:border-b-0 border-neutral-160 py-4 md:py-0 px-8 md:px-0">
            <img
              src={collection.imageUrl}
              alt={collection.name}
              className="h-10 rounded-full"
            />

            <span className="font-medium">{collection.name}</span>
          </p>

          <div className="space-x-4 lg:space-x-6 border-b md:border-b-0 border-neutral-160 py-4 md:py-0 px-8 md:px-0">
            <a href={collection?.websiteUrl} target={"_blank"} rel="noreferrer">
              Home
            </a>
            <a href={collection?.twitterUrl} target={"_blank"} rel="noreferrer">
              Twitter
            </a>
            <a href={collection?.discordUrl} target={"_blank"} rel="noreferrer">
              Discord
            </a>
            <a
              href={collection?.magicEdenUrl}
              target={"_blank"}
              rel="noreferrer"
            >
              Magic Eden
            </a>
          </div>
          {wallet.connected && (<div className="space-x-4 lg:space-x-6 border-b md:border-b-0 border-neutral-160 py-4 md:py-0 px-8 md:px-0">
            <Button onClick={wallet.disconnect}>
                Disconnect
              </Button>
          </div>)}
          
        </header>
        <main className="mb-auto pt-40 md:pt-12 lg:pt-16 px-6 lg:px-0">
          <PickWallet open={pickWalletOpen} setOpen={setPickWalletOpen} />
          {(wallet.connected && loadingFarmInformation) ||
            wallet.connecting ||
            loadingFarmAccount ? (
            <div className="w-full flex justify-center">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
          {wallet.connected || loadingFarmAccount ? null : (
            <div className="flex flex-col items-center max-w-5xl mx-auto">
              <img
                alt={collection?.name}
                src={collection?.imageUrl}
                className="w-32 h-32 rounded-full shadow-lg"
              />

              <h1 className="text-3xl md:text-5xl font-bold mt-8 text-center">
                {collection.name} Staking
              </h1>

              <div className="mt-10 flex flex-col lg:flex-row w-full space-y-5 md:space-y-0">
                <div className="w-full md:w-1/3">
                  <InfoItem
                    label={`Total ${collection.nftNamePlural} staked`}
                    value={farmStats.totalStaked}
                  />
                </div>
                <div className="w-full md:w-1/3">
                  <InfoItem
                    label={`% of ${collection?.nftNamePlural} Staked`}
                    value={farmStats.percentageStaked}
                  />
                </div>
                <div className="w-full md:w-1/3">
                  <InfoItem
                    label={`Minimum Staked Value`}
                    value={farmStats.minimumValueStaked}
                  />
                </div>
              </div>

              <div className="mt-16 mb-4">
                <button
                  className="h-14 px-6 wallet-connect-button font-semibold bg-[#6333ff] hover:bg-[#7347ff] transition ease-linear"
                  onClick={() => {
                    setPickWalletOpen(true);
                  }}
                  style={{
                    // backgroundColor: "#6333ff",
                    borderRadius: "9px",
                    // #7347ff
                    color: "#fff",
                  }}
                >
                  Connect wallet
                </button>
              </div>
            </div>
          )}
          {wallet.connected && !loadingFarmInformation ? (
            <div className="w-full max-w-6xl mx-auto">
              <div className="flex flex-col space-y-5 md:space-y-0 justify-between md:flex-row items-center">
                <InfoItem
                  label={`Your wallet`}
                  value={(() => {
                    const base58 = wallet.publicKey?.toBase58();
                    return `${base58?.slice(0, 4) + "..." + base58?.slice(-4)}`;
                  })()}
                  size="small"
                />

                <InfoItem
                  label={`${collection.nftNamePlural} staked`}
                  value={totalStaked.toString()}
                  size="small"
                />

                <EstimatedRewards collection={collection} />
                <InfoItem
                  label={`Reward rate`}
                  value={`${farmerStats?.totalRewardRate} ${collection?.rewardSymbol} / day`}
                  size="small"
                />
              </div>

              <div
                className={classNames(
                  "my-12 md:my-16 flex flex-col md:flex-row",
                  {
                    "justify-between": canClaim || canStakeAll,
                    "justify-center": !canClaim && !canStakeAll,
                  }
                )}
              >
                <nav className="flex items-center space-x-4 md:space-x-6 mb-4 md:mb-0">
                  {["All", "Staked", "Unstaked"].map((filter) => (
                    <button
                      key={filter}
                      onClick={() => setNftFilter(filter)}
                      className={classNames(
                        "rounded-[9px] h-14 px-6 border-2 ",
                        {
                          "border-[#6333ff]": filter === nftFilter,
                          "border-neutral-160": filter !== nftFilter,
                        }
                      )}
                    >
                      {filter}
                    </button>
                  ))}
                </nav>

                <div className="flex items-center space-x-3">
                  {canClaim ? (
                    <Button
                      size="large"
                      variant="success"
                      onClick={onClaim}
                      isLoading={isClaiming}
                    >
                      Claim {collection?.rewardSymbol}
                    </Button>
                  ) : null}

                  {canStakeAll ? (
                    <Button
                      size="large"
                      variant='primary'
                      onClick={bulkStakeNft}
                      isLoading={stakingAll}
                    >
                      Stake all
                    </Button>
                  ) : null}
                </div>
              </div>

              <div className="mb-16 w-full flex flex-col">
                {tokens?.length === 0 ? (
                  <div className="max-w-3xl mx-auto">
                    {notFoundTokensMessage}
                  </div>
                ) : null}
                {tokens.length > 0 ? (
                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                    {tokens.map((nft) => {
                      const lockDetails = nft.lockDetails;

                      const isLocked = lockDetails?.isLocked

                      const rate = nft?.staked
                        ? lockDetails?.rewardA?.rewardTier?.rewardRate! /
                        10 ** parseInt(collection?.decimals)
                        : 0;

                      return (
                        <div
                          className="w-full relative"
                          key={nft.gemMint?.toBase58()}
                        >
                          <div className="relative">
                            {nft.image ? (
                              <Image
                                height={296}
                                width={296}
                                alt={nft.name}
                                layout="responsive"
                                src={nft?.image}
                                className="rounded-xl shadow-lg"
                                id={`nftImage-${nft?.gemMint?.toBase58()}`}
                              />
                            ) : null}

                            {nft?.staked ? (
                              <div className="w-full h-full absolute top-0 bg-[#000]/75 flex items-center justify-center rounded-[9px] text-white">
                                <span className="text-white text-xs font-bold staked">
                                  Staked{isLocked ? " and Locked" : ""}
                                </span>
                              </div>
                            ) : null}
                          </div>

                          <div className="relative p-4 border-b-2 border-l-2 border-r-2 border-neutral-160 shadow-md rounded-b-[9px]">
                            <div className="absolute border-l border-neutral-160 left-[-1px] top-[-6px] h-[16px]"></div>
                            <div className="absolute border-r border-neutral-160 right-[-1px] top-[-6px] h-[16px]"></div>
                            <p className="mb-2">{nft?.name}</p>
                            <div className="flex justify-between items-center">
                              <p className="mb-3 text-xs font-medium text-[#6333ff]">
                                {nft?.staked
                                  ? numeral(rate).format("0,00")
                                  : "0"}{" "}
                                {collection?.rewardSymbol} / day
                              </p>

                              {nft?.staked && isLocked ? (
                                <p className="mb-3 text-xs font-medium text-[#6333ff]">
                                  <Countdown date={lockDetails?.lockExpiry} />
                                </p>
                              ) : null}
                            </div>
                            <Button
                              size="large"
                              variant={nft?.staked ? "danger" : "primary"}
                              onClick={() => {
                                if (tierOptions.length > 1 && !nft.staked) {
                                  setSelectedNft(nft);
                                  setPickingStakingTier(true);

                                  return;
                                }

                                if (nft.staked) {
                                  if (isLocked) {
                                    setSelectedNft(nft);
                                    setConfirmingBreakBank(true);
                                    return;
                                  }

                                  return unstakeNft(nft);
                                }

                                stakeNft(nft);
                              }}
                              isLoading={
                                isStaking?.name === nft.name ||
                                isUnstaking?.name === nft?.name || (stakingAll && !nft.staked)
                              }
                            >
                              {nft?.staked ? "Unstake" : "Stake"}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </main>
        {/* <footer
          className="h-14 border-t flex items-center justify-center text-xs"
          style={{ borderColor: "#e8e5dd" }}
        >
          Powered by the
          <a href="https://twitter.com/solApeArmy" className="ml-1">
            {" "}
            Guardian farms
          </a>
        </footer> */}
      </div>
    </>
  );
}
