import { useEffect, useState } from 'react';

export function useValueLocked() {
  const [value, setValue] = useState(0)
  const [failed, setFailed] = useState(true) // coming soon
  const [loading, setLoading] = useState(false);

  async function computeValue() {
    await Promise.all([
      fetch(
        'https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/jellybabies?edge_cache=true'
      ).then((response) => response.json()),
      fetch('https://data.messari.io/api/v1/assets/solana/metrics').then(
        (response) => response.json()
      ),
    ])
    .then(([nftStats, solanaStats]) => {
        setLoading(false)
        setFailed(false)

        const floorPrice = nftStats.results.floorPrice
        const priceInUsd = solanaStats.data.market_data.price_usd
    
        setValue(floorPrice * priceInUsd)
    })
    .catch(() => {
        setFailed(true)
        setLoading(false)
        return []
    });
  }

  useEffect(() => {
    // computeValue() // coming soon
  }, []);

  return { loading, value, failed };
}
